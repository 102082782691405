require('./bootstrap');

import select2 from 'select2'
import '../../node_modules/select2/dist/css/select2.min.css'
import '../../node_modules/@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'
$.fn.select2.defaults.set("theme", "bootstrap4");
$.fn.select2.defaults.set("allowClear", true);

$('nav.navbar .dropdown-menu').click(function(e) {
    e.stopPropagation();
});